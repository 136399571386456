import { render, staticRenderFns } from "./InsuranceSubprogramEdit.vue?vue&type=template&id=9d64a908&scoped=true"
import script from "./InsuranceSubprogramEdit.vue?vue&type=script&lang=js"
export * from "./InsuranceSubprogramEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d64a908",
  null
  
)

export default component.exports