<template>
  <div class="w-100">
    <loading
      v-if="isLoading"
      class="mx-auto"
    >
      Загрузка данных подпрограммы
    </loading>

    <template v-else>
      <InsuranceSubprogramCreatePageOMS
        v-if="isOms"
        :initial-data="initialData"
      />

      <InsuranceSubprogramCreatePageDMS
        v-else
        :initial-data="initialData"
      />
    </template>
  </div>
</template>

<script>
import Loading from '@/components/Loading';

import InsuranceSubprogramCreatePageOMS from '@/components/InsurancePrograms/InsuranceSubprogramCreatePageOMS';
import InsuranceSubprogramCreatePageDMS from '@/components/InsurancePrograms/InsuranceSubprogramCreatePageDMS';

export default {
  name: 'InsuranceSubprogramEdit',
  components: {
    Loading,
    InsuranceSubprogramCreatePageOMS,
    InsuranceSubprogramCreatePageDMS,
  },
  data() {
    return {
      isLoading: false,
      company: null,
      initialData: null,
    };
  },
  computed: {
    isExist() {
      return !!this.$route.params.subprogram;
    },
    isOms() {
      return this.company.isOms;
    },
    companyId() {
      return this.$route.params.company;
    },
    subprogramId() {
      return this.$route.params.subprogram;
    },
  },
  async created() {
    this.isLoading = true;

    const { company } = await this.$store.dispatch(this.$types.COMPANY_FETCH, this.companyId);
    this.company = company;

    if (this.isExist) {
      // const data,{ company, program, subProgram } = await this.$store.dispatch(this.$types.SUBPROGRAM_FETCH, this.subprogramId);
      const { subProgram } = await this.$store.dispatch(this.$types.SUBPROGRAM_FETCH, this.subprogramId);
      this.initialData = subProgram;
    }

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
</style>
